@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Prata&family=Quicksand:wght@300;400;500;600;700&family=Tenor+Sans&display=swap');
.App {
  text-align: center;
  background-color: #eeeeee;
  font-family: 'Tenor Sans', 'Quicksand', sans-serif;
  min-height: 100vh;
}

.header-logo {
  font-family: 'Abhaya Libre', 'Prata', sans-serif;
  font-size: 28px;
  font-weight: 500;
}
@media screen and (max-width: 480px) {
  .header-logo {
    font-size: 18px;
  }
}
@media screen and (max-width: 780px) and (min-width: 481px) {
  .header-logo {
    font-size: 20px
  }
}


.main-image {
  width: 100%;
}

.modal-90w {
  min-width: 80%;
}



.button-27 {
  appearance: none;
  background-color: #254352;
  border: 2px solid #254352;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 20px;
  min-width: 0;
  outline: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 50%;
  will-change: transform;
  margin-top: 50px;
  letter-spacing: 5px;
}

.button-27:disabled {
pointer-events: none;
}

.button-27:hover {
box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
transform: translateY(-2px);
}

.button-27:active {
box-shadow: none;
transform: translateY(0);
}